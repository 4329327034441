var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as React from "react";
var OtherAllergies = /** @class */ (function (_super) {
    __extends(OtherAllergies, _super);
    function OtherAllergies() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            options: [],
        };
        _this.addOtherAllergy = function (value) {
            _this.setState({ options: __spreadArray(__spreadArray([], _this.state.options, true), [value], false) }, function () {
                _this.props.onValueAdded(value);
            });
        };
        _this.keyUp = function (ev) {
            if (ev.key === 'Enter') {
                ev.preventDefault();
                ev.stopPropagation();
                _this.addOtherAllergy(ev.currentTarget.value);
                ev.currentTarget.value = '';
            }
        };
        _this.addViaButton = function (event) {
            event.preventDefault();
            var input = event.currentTarget.previousElementSibling;
            _this.addOtherAllergy(input.value);
            input.value = '';
        };
        _this.removeValue = function (value) {
            _this.setState({
                options: _this.state.options.filter(function (selected) { return selected !== value; })
            }, function () {
                _this.props.onValueRemoved(value);
            });
        };
        _this.render = function () {
            return (React.createElement("div", { className: "other-allergies" },
                React.createElement("div", { className: "add-allergy-wrapper" },
                    React.createElement("input", { type: "text", onKeyUp: _this.keyUp, className: "form__control add-allergy-wrapper__control", placeholder: "Enter other allergies" }),
                    React.createElement("button", { type: "button", className: "add-allergy-wrapper__button", onClick: _this.addViaButton }, "Add +")),
                React.createElement("div", { className: "other-allergies__selected" }, _this.state.options.map(function (option, idx) {
                    return React.createElement("div", { className: "pill", key: "other-allergy-" + idx },
                        option,
                        React.createElement("button", { type: "button", className: "pill__remove", onClick: function () { return _this.removeValue(option); } },
                            React.createElement("svg", null,
                                React.createElement("use", { xlinkHref: "#icon--close" })),
                            React.createElement("span", null, "Remove")));
                }))));
        };
        return _this;
    }
    return OtherAllergies;
}(React.PureComponent));
export default OtherAllergies;
